/*!

=========================================================
* Argon Design System React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-design-system-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-design-system-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
// nodejs library that concatenates classes
import classnames from "classnames";
import BlogSummary from "./../../components/BlogSummary"
import NewsLetterForm from "./NewsLetterForm";

// reactstrap components
import {
  Badge,
  Button,
  Card,
  CardBody,
  CardImg,
  Container,
  Row,
  Col,
  UncontrolledCarousel ,
  FormGroup,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
} from "reactstrap";

// core components
import DemoNavbar from "components/Navbars/DemoNavbar.js";
import SimpleFooter from "components/Footers/SimpleFooter.js";

const items = [
  {
    src: require("assets/img/theme/compliance-2.jpg"),
    altText: "",
    caption: "",
    header: ""
  },
  {
    src: require("assets/img/theme/compliance-3.jpg"),
    altText: "",
    caption: "",
    header: ""
  },
  {
    src: require("assets/img/theme/compliance-4.jpg"),
    altText: "",
    caption: "",
    header: ""
  }
];


class Landing extends React.Component {
  state = {};
  componentDidMount() {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    this.refs.main.scrollTop = 0;
  }

  render() {
    return (
      <>
        <DemoNavbar />
        <main ref="main">
          <div className="position-relative">
            {/* shape Hero */}
            <section className="section section-lg section-shaped pb-250">
              <div className="shape shape-style-1 shape-default">
                <span />
                <span />
                <span />
                <span />
                <span />
                <span />
                <span />
                <span />
                <span />
              </div>
              <Container className="py-lg-md d-flex">
                <div className="col px-0">
                  <Row>
                    <Col lg="6">
                      <h1 className="display-3 text-white">
                        Your system for monitoring food safety and quality{" "}
                        <span>Easy to use</span>
                      </h1>
                      <p className="lead text-white">
                        Compliance software facilitates seamless document exchange between clients and suppliers as well as the digital transformation of the food quality and safety management system. 
                        
                      </p>
                      <div className="btn-wrapper">
                        <Button
                          className="btn-icon mb-3 mb-sm-0"
                          color="info"
                          href=""
                        >
                          <span className="btn-inner--icon mr-1">
                            <i className="fa fa-code" />
                          </span>
                          <span className="btn-inner--text">Free trial</span>
                        </Button>
                        <Button
                          className="btn-white btn-icon mb-3 mb-sm-0 ml-1"
                          color="default"
                          href=""
                        >
                          <span className="btn-inner--icon mr-1">
                            <i className="ni ni-cloud-download-95" />
                          </span>
                          <span className="btn-inner--text">
                            Info video
                          </span>
                        </Button>
                      </div>
                    </Col>
                    <Col className="mb-lg-auto" lg="6">
                      <div className="rounded shadow-lg overflow-hidden transform-perspective-right">
                        <UncontrolledCarousel items={items} />
                      </div>
                    </Col>
                  </Row>
                </div>
              </Container>
              {/* SVG separator */}
              <div className="separator separator-bottom separator-skew">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  preserveAspectRatio="none"
                  version="1.1"
                  viewBox="0 0 2560 100"
                  x="0"
                  y="0"
                >
                  <polygon
                    className="fill-white"
                    points="2560 0 2560 100 0 100"
                  />
                </svg>
              </div>
            </section>
            {/* 1st Hero Variation */}
          </div>
          <section className="section section-lg pt-lg-0 mt--200">
            <Container>
              <Row className="justify-content-center">
                <Col lg="12">
                  <Row className="row-grid">
                    <Col lg="4">
                      <Card className="card-lift--hover shadow border-0">
                        <CardBody className="py-5">
                          <div className="icon icon-shape icon-shape-primary rounded-circle mb-4">
                            <i className="ni ni-check-bold" />
                          </div>
                          <h6 className="text-primary text-uppercase">
                              Quality and food safety document management
                          </h6>
                          <p className="description mt-3">
                              Automate and digitalize your food safety and quality control system.
                              <br />
                              Manage and rapidly distribute documents like COAs, profuct specifications, and supplier certifications in a secure manner.
                              
                          </p>

                           <br />
                          <div>
                            <Badge color="primary" pill className="mr-1">
                              Smart-COA
                            </Badge>
                            <Badge color="primary" pill className="mr-1">
                              Smart-SPEC
                            </Badge>
                            <Badge color="primary" pill className="mr-1">
                              Smart-NCR
                            </Badge>
                            <Badge color="primary" pill className="mr-1">
                              Smart-SQMS
                            </Badge>
                            <Badge color="primary" pill className="mr-1">
                              Smart-RMQC
                            </Badge>
                            <Badge color="primary" pill className="mr-1">
                              Smart-QDM
                            </Badge>


                          </div>
                          <Button
                            className="mt-4"
                            color="primary"
                            href="#pablo"
                            onClick={e => e.preventDefault()}
                          >
                            Learn more
                          </Button>
                        </CardBody>
                      </Card>
                    </Col>
                    <Col lg="4">
                      <Card className="card-lift--hover shadow border-0">
                        <CardBody className="py-5">
                          <div className="icon icon-shape icon-shape-success rounded-circle mb-4">
                            <i className="ni ni-istanbul" />
                          </div>
                          <h6 className="text-success text-uppercase">
                            Suppliers Database
                          </h6>
                          <p className="description mt-3">
                              Search our database of all global food suppliers to source your raw materials, product and services.
                              Validate known suppliers by viewing their quality and safety documention and product specifications.
                              Request samples, quotations and technical infomation. 
                              Compare prices between suppliers.
                          </p>
                          <div>
                            <Badge color="success" pill className="mr-1">
                              business
                            </Badge>
                            <Badge color="success" pill className="mr-1">
                              Industries
                            </Badge>
                            <Badge color="success" pill className="mr-1">
                              Certification
                            </Badge>
                            <Badge color="success" pill className="mr-1">
                              Samples
                            </Badge>
                          </div>
                          <Button
                            className="mt-4"
                            color="success"
                            href="#pablo"
                            onClick={e => e.preventDefault()}
                          >
                            Learn more
                          </Button>
                        </CardBody>
                      </Card>
                    </Col>
                    <Col lg="4">
                      <Card className="card-lift--hover shadow border-0">
                        <CardBody className="py-5">
                          <div className="icon icon-shape icon-shape-warning rounded-circle mb-4">
                            <i className="ni ni-planet" />
                          </div>
                          <h6 className="text-warning text-uppercase">
                            Food Safety Management Systems Builder
                          </h6>
                          <p className="description mt-3">
                            Smart and easy to use  Software to helps you create and maintain a fully customizable digital  food safety system (BRC, FSSC2200, ISO22000, HACCP, FSA etc..)
                            Access to multiple templates and automatically generated monitoring checks and real-time dashboards

                          </p>
                          <div>
                            <Badge color="warning" pill className="mr-1">
                              Food Labeling
                            </Badge>
                            <Badge color="warning" pill className="mr-1">
                              Nutrition Information
                            </Badge>
                            <Badge color="warning" pill className="mr-1">
                              Haccp
                            </Badge>
                            <Badge color="warning" pill className="mr-1">
                              ISO22000
                            </Badge>
                          </div>
                          <Button
                            className="mt-4"
                            color="warning"
                            href="#pablo"
                            onClick={e => e.preventDefault()}
                          >
                            Learn more
                          </Button>
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Container>
          </section>
          <section className="section section-lg">
            <Container>
              <Row className="row-grid align-items-center">
                <Col className="order-md-2" md="6">
                  <img
                    alt="..."
                    className="img-fluid floating"
                    src={require("assets/img/theme/promo-1.png")}
                  />
                </Col>
                <Col className="order-md-1" md="6">
                  <div className="pr-md-5">
                    <div className="icon icon-lg icon-shape icon-shape-success shadow rounded-circle mb-5">
                      <i className="ni ni-settings-gear-65" />
                    </div>
                    <h3>Awesome features</h3>
                    <p>
                      Powerfull features that will help to automate and digistalise your food safety and quality system. 
                      <br /> 
                      <br /> 
                      Food Safety and quality software Solutions for:
                    </p>
                    <ul className="list-unstyled mt-5">
                      <li className="py-2">
                        <div className="d-flex align-items-center">
                          <div>
                            <Badge
                              className="badge-circle mr-3"
                              color="success"
                            >
                              <i className="ni ni-settings-gear-65" />
                            </Badge>
                          </div>
                          <div>
                            <h6 className="mb-0">
                              SMART-CAO : Portal to manage and instantly share COAs with your customers
                            </h6>
                          </div>
                        </div>
                      </li>
                      <li className="py-2">
                        <div className="d-flex align-items-center">
                          <div>
                            <Badge
                              className="badge-circle mr-3"
                              color="success"
                            >
                              <i className="ni ni-html5" />
                            </Badge>
                          </div>
                          <div>
                            <h6 className="mb-0">SMART-DOC: Manage and tract all your  food compiance documents</h6>
                          </div>
                        </div>
                      </li>
                      <li className="py-2">
                        <div className="d-flex align-items-center">
                          <div>
                            <Badge
                              className="badge-circle mr-3"
                              color="success"
                            >
                              <i className="ni ni-satisfied" />
                            </Badge>
                          </div>
                          <div>
                            <h6 className="mb-0">
                              SMART-SPECS: Transform specification management for raw materials, finished products and share it instantly with your customers
                            </h6>
                          </div>
                        </div>
                      </li>
                      <li className="py-2">
                        <div className="d-flex align-items-center">
                          <div>
                            <Badge
                              className="badge-circle mr-3"
                              color="success"
                            >
                              <i className="ni ni-satisfied" />
                            </Badge>
                          </div>
                          <div>
                            <h6 className="mb-0">
                              SMART-NCR: Automate supplier quality monitoring or internal non-conformance management with automated workflows to ensure prompt closure of non-conformances
                            </h6>
                          </div>
                        </div>
                      </li>
                      <li className="py-2">
                        <div className="d-flex align-items-center">
                          <div>
                            <Badge
                              className="badge-circle mr-3"
                              color="success"
                            >
                              <i className="ni ni-satisfied" />
                            </Badge>
                          </div>
                          <div>
                            <h6 className="mb-0">
                              SMART-SQMS: Your supplier quality management system
                            </h6>
                          </div>
                        </div>
                      </li>
                      <li className="py-2">
                        <div className="d-flex align-items-center">
                          <div>
                            <Badge
                              className="badge-circle mr-3"
                              color="success"
                            >
                              <i className="ni ni-satisfied" />
                            </Badge>
                          </div>
                          <div>
                            <h6 className="mb-0">
                              SMART-LABELS: Manage your product labels with automation ingredients declarations and 
                              nutriational information
                            </h6>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>
                </Col>
              </Row>
            </Container>
          </section>
          <BlogSummary />
          <section className="section section-lg">
            <Container>
              <Row className="justify-content-center text-center mb-lg">
                <Col lg="8">
                  <h2 className="display-3">About Us</h2>
                  <p className="lead text-muted">
                    Agrowhizz unifies all facets of Food Safety and Quality Management compliance in a single, user-friendly solution..
                  
                    Agrowhizz is a one stop shop for supplier management and product approval.
                    <br />
                    <br />
                    Our main objective is to offer services of the highest caliber at reasonable prices. We provide organizations aiming to enhance food safety procedures, maintain regulatory compliance, and safeguard their brand identity with specialized, custom tailored software for food safety and quality.
                    <br />
                    <br />
                    We assist small, medium-sized, and big enterprises in achieving their goals.
                    <br />
                    <br />
                    Our program produces workable solutions for issues including labeling, quality control, regulatory compliance, and others involving food safety and quality.
                    <br />
                    <br />
                    <a
                          className="font-weight-bold text-success mt-5"
                          href="/contact-page"
                          //to="/login-page"
                          //onClick={e => e.preventDefault()}
                    > Contact our team </a> today to discuss your requirements.

                  </p>
                </Col>
              </Row>
              <Row>
                
                <Col>
                  <div className="px-4">
                    <img
                      alt="..."
                      className="rounded-circle img-center img-fluid shadow shadow-lg--hover"
                      src={require("assets/img/theme/patrickb.jpg")}
                      style={{ width: "200px" }}
                    />
                    <div className="pt-4 text-center">
                      <h5 className="title">
                        <span className="d-block mb-1">Patrick Bope</span>
                        <small className="h6 text-muted">Founder and CEO</small>
                      </h5>
                      <div className="mt-3">
                        <Button
                          className="btn-icon-only rounded-circle"
                          color="success"
                          href="#pablo"
                          onClick={e => e.preventDefault()}
                        >
                          <i className="fa fa-twitter" />
                        </Button>
                        <Button
                          className="btn-icon-only rounded-circle ml-1"
                          color="success"
                          href="#pablo"
                          onClick={e => e.preventDefault()}
                        >
                          <i className="fa fa-facebook" />
                        </Button>
                        <Button
                          className="btn-icon-only rounded-circle ml-1"
                          color="success"
                          href="#pablo"
                          onClick={e => e.preventDefault()}
                        >
                          <i className="fa fa-dribbble" />
                        </Button>
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            </Container>
          </section>
          
          <section className="section section-lg pt-0">
            <Container className="pt-lg pb-300">
              <Card className="bg-gradient-warning shadow-lg border-0 ">
                <div className="p-5">
                  <Row className="align-items-center">
                    <Col lg="8">
                      <h3 className="text-white">
                        We made food safety and quality compliance easier for you.
                      </h3>
                      <p className="lead text-white mt-3">
                        Do you want to digitalize, automate, and change your food safety and quality compliance processes? You’re in the right place!.
                      </p>
                    </Col>
                    <Col className="ml-lg-auto" lg="3">
                      <Button
                        block
                        className="btn-white"
                        color="default"
                        href="/contact-page"
                        size="lg"
                      >
                        REQUEST A DEMO
                      </Button>
                    </Col>
                  </Row>
                </div>
              </Card>
            </Container>
          </section>
          <NewsLetterForm />
        </main>
        <SimpleFooter />
      </>
    );
  }
}

export default Landing;
