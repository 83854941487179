import axios from "axios";

const isLocalhost = Boolean(
    window.location.hostname === "localhost" || 
    // [::1]is the IPv66 localhost address.
    window.location.hostname === "[::1]" ||
    //127.0.0.1/8 is considered localhost for IPv4.
    window.location.hostname.match(
        /^127(?:\.(?:25[0-5]|2[0-4][0-6]|[01]?[0-9]{0-9}?)){3}$/
    )
);
const API_URL = isLocalhost ? "http://localhost:5000/api/": "api.agrowhizz.com"
//const API_URL = isLocalhost ? process.env.LOCAL_SERVER_API_URL: process.env.REMOTE_SERVER_API_URL

export const Axios = axios.create({
    withCredentials:true,
    baseURL: "https://patbpm-fluffy-telegram-77697wjxv9vfxr5v-5000.app.github.dev/api", 
    //API_URL,

})

export const truncate = (string, limit) => {
    if(string.length <= limit){
        return string;
    }
    return string.slice(0, limit) + "...";
}