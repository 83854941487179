/*!

=========================================================
* Argon Design System React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-design-system-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-design-system-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, {useEffect, useState, useRef}  from "react";
// nodejs library that concatenates classes
import classnames from "classnames";

// reactstrap components
import {
  Badge,
  Button,
  Card,
  CardBody,
  CardImg,
  FormGroup,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col,
  UncontrolledAlert,
} from "reactstrap";

// core components
import DemoNavbar from "components/Navbars/DemoNavbar.js";
import SimpleFooter from "components/Footers/SimpleFooter.js";
import { useForm, Controller } from "react-hook-form";
import emailjs from "emailjs-com";


function Contact (){
    const [nameFocused, setNameFocused]= useState(false);
    const [alert, setAlert]= useState('failed');
    const [visible, setVisible]= useState(false);
    const { register, handleSubmit, reset, control, formState: { errors, isSubmitSuccessful } } = useForm();
    const form = useRef();
    const onDismiss = () => setVisible(false);

    const alerting = () => {
      return (<>
      {alert === 'Succeeded'?
          <UncontrolledAlert color="success" fade={true} isOpen={visible} toggle={onDismiss}>
              <span className="alert-inner--icon">
                  <i className="ni ni-like-2" />
              </span>
              <span className="alert-inner--text ml-1">
                  <strong>Success!</strong> Your inquiry have been sent!
              </span>
          </UncontrolledAlert>
          :
          <UncontrolledAlert color="danger" fade={false} isOpen={visible} toggle={onDismiss}>
              <span className="alert-inner--icon">
                  <i className="ni ni-support-16" />
              </span>
              <span className="alert-inner--text ml-1">
                  <strong>Error!</strong> Failed to send your inquiry. Please try again or send us an email to info@agrowhizz.com!
              </span>
          </UncontrolledAlert>}
          
      </>)
    }

    const sendEmail = (e) => {
      emailjs.sendForm(process.env.REACT_APP_SERVICE_ID,
        process.env.REACT_APP_CONTACT_TEMPLATE_ID,
        form.current,
        process.env.REACT_APP_PUBLIC_KEY)

        .then((result) => {
            console.log(result.text);
            setAlert("Succeeded")
        }, (error) => {
            console.log(error.text);
            setAlert("failed")
        });
      reset();
    };
  
    return (
      <>
        <DemoNavbar />
        <main >
          <section className="section section-lg bg-gradient-default">
            <Container className="pt-lg pb-300">
              <Row className="text-center justify-content-center">
                <Col lg="10">
                  <h2 className="display-3 text-white">Quick and easy tools for your food safety and quality</h2>
                  <p className="lead text-white">
                    We strive to make managing food safety and quality as simple as possible. You, your business, and your supplier may all enjoy the tranquility you require to perform at your very best.
                  </p>
                </Col>
              </Row>
              {/*
              <Row className="row-grid mt-5">
                <Col lg="4">
                  <div className="icon icon-lg icon-shape bg-gradient-white shadow rounded-circle text-primary">
                    <i className="ni ni-settings text-primary" />
                  </div>
                  <h5 className="text-white mt-3">Building tools</h5>
                  <p className="text-white mt-3">
                    Some quick example text to build on the card title and make
                    up the bulk of the card's content.
                  </p>
                </Col>
                <Col lg="4">
                  <div className="icon icon-lg icon-shape bg-gradient-white shadow rounded-circle text-primary">
                    <i className="ni ni-ruler-pencil text-primary" />
                  </div>
                  <h5 className="text-white mt-3">Grow your market</h5>
                  <p className="text-white mt-3">
                    Some quick example text to build on the card title and make
                    up the bulk of the card's content.
                  </p>
                </Col>
                <Col lg="4">
                  <div className="icon icon-lg icon-shape bg-gradient-white shadow rounded-circle text-primary">
                    <i className="ni ni-atom text-primary" />
                  </div>
                  <h5 className="text-white mt-3">Launch time</h5>
                  <p className="text-white mt-3">
                    Some quick example text to build on the card title and make
                    up the bulk of the card's content.
                  </p>
                </Col>
              </Row>*/}
          </Container>
            {/* SVG separator */}
            <div className="separator separator-bottom separator-skew zindex-100">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                preserveAspectRatio="none"
                version="1.1"
                viewBox="0 0 2560 100"
                x="0"
                y="0"
              >
                <polygon
                  className="fill-white"
                  points="2560 0 2560 100 0 100"
                />
              </svg>
            </div>
          </section>
          <section className="section section-lg pt-lg-0 section-contact-us">
            <Container>
              <Row className="justify-content-center mt--300">
                <Col lg="8">
                  <Card className="bg-gradient-secondary shadow">
                    <CardBody className="p-lg-5">
                      <h4 className="mb-1 text-center">Get in touch</h4>
                      <p className="mt-0 text-center">
                      Our team is happy to answer all your questions and help you!
                      Just leave us a note below, and we will get back to you as soon as possible
                      </p>
                      <form ref={form} onSubmit={handleSubmit(sendEmail)}>
                        {alerting()}
                        <FormGroup
                          className={classnames("mt-5", {
                            focused: nameFocused
                          })}
                        >
                          <InputGroup className="input-group-alternative">
                            <InputGroupAddon addonType="prepend">
                              <InputGroupText>
                                <i className="ni ni-user-run" />
                              </InputGroupText>
                            </InputGroupAddon>
                            <input
                              className="form-control"
                              placeholder="Your first name"
                              type="text"
                              onFocus={e => setNameFocused(true)}
                              onBlur={e => setNameFocused(false)}
                              {...register("firstName", { required: true})}
                            />
                          </InputGroup>
                        </FormGroup>
                        <FormGroup
                          className={classnames({
                            focused: nameFocused
                          })}
                        >
                          <InputGroup className="input-group-alternative">
                            <InputGroupAddon addonType="prepend">
                              <InputGroupText>
                                <i className="ni ni-user-run" />
                              </InputGroupText>
                            </InputGroupAddon>
                            <input
                              className="form-control"
                              placeholder="Your last name"
                              type="text"
                              onFocus={e => setNameFocused(true)}
                              onBlur={e => setNameFocused(false)}
                              {...register("lastName", { required: true})}
                            />
                          </InputGroup>
                        </FormGroup>
                        <FormGroup
                          className={classnames({
                            focused: nameFocused
                          })}
                        >
                          <InputGroup className="input-group-alternative">
                            <InputGroupAddon addonType="prepend">
                              <InputGroupText>
                                <i className="ni ni-user-run" />
                              </InputGroupText>
                            </InputGroupAddon>
                            <input
                              className="form-control"
                              placeholder="Your Company name"
                              type="text"
                              onFocus={e => setNameFocused(true)}
                              onBlur={e => setNameFocused(false)}
                              {...register("companyName", { required: true})}
                            />
                          </InputGroup>
                        </FormGroup>
                        <FormGroup
                          className={classnames({
                            focused: nameFocused
                          })}
                        >
                          <InputGroup className="input-group-alternative">
                            <InputGroupAddon addonType="prepend">
                              <InputGroupText>
                                <i className="ni ni-email-83" />
                              </InputGroupText>
                            </InputGroupAddon>
                            <input
                              className="form-control"
                              placeholder="Your email address"
                              type="email"
                              onFocus={e => setNameFocused(true)}
                              onBlur={e => setNameFocused(false)}
                              {...register("emailAddress", { required: true})}
                            />
                          </InputGroup>
                        </FormGroup>
                        <FormGroup
                          className={classnames({
                            focused: nameFocused
                          })}
                        >
                          <InputGroup className="input-group-alternative">
                            <InputGroupAddon addonType="prepend">
                              <InputGroupText>
                                <i className="ni ni-headphones" />
                              </InputGroupText>
                            </InputGroupAddon>
                            <input
                              className="form-control"
                              placeholder="Your phone Number"
                              type="text"
                              onFocus={e => setNameFocused(true)}
                              onBlur={e => setNameFocused(false)}
                              {...register("phoneNumber", { required: true})}
                            />
                          </InputGroup>
                        </FormGroup>
                        <FormGroup className="mb-4">
                          <textarea
                            className="form-control"
                            cols="80"
                            name="name"
                            placeholder="Type a message..."
                            rows="4"
                            type="textarea"
                            {...register("message", { required: true})}
                          />
                        </FormGroup>
                        <div>
                          <Button
                            block
                            className="btn-round"
                            color="default"
                            size="lg"
                            type="submit"
                          >
                            Send Message
                          </Button>
                        </div>

                      </form>
                      
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Container>
          </section>
        </main>
        <SimpleFooter />
      </>
    );
  
}

export default Contact;
