import React, {useEffect, useState} from "react";
import { getMostRecentPost, getMostRecentPocketPost } from "./../services/api/index";
import moment from "moment";
import { truncate } from "services/config";
// reactstrap components
import {
    Badge,
    Button,
    Card,
    CardBody,
    CardImg,
    Container,
    Row,
    Col,
    UncontrolledCarousel ,
    FormGroup,
    Input,
    InputGroupAddon,
    InputGroupText,
    InputGroup,
  } from "reactstrap";

function BlogSummary (){
    const [posts, setPosts]= useState([]);
    const [mostRecentPost, setMostRecentPost]= useState([]);
    const [mostRecentPocketPost, setMostRecentPocketPost]= useState([]);
    const [isError, setIsError] = useState(false);
    const [loading, setLoading] = useState(true);

    useEffect(()=> {
        const fetchData = async() => {
            const res = await getMostRecentPost(6);
            setIsError(res.error);
            setPosts(res.data);
            setLoading(false)
        };
        fetchData();
        return () => {};
    }, []);

    useEffect(()=> {
        const fetchData = async() => {
            const res = await getMostRecentPost(1);
            setIsError(res.error);
            setMostRecentPost(res.data);
            setLoading(false)
        };
        fetchData();
        return () => {};
    }, []);

   /* useEffect(()=> {
      const fetchData = async() => {
          const res = await getMostRecentPocketPost();
          //setIsError(res.error);
          setMostRecentPocketPost(res.data);
          //setLoading(false)
      };
      fetchData();
      return () => {};
  }, []);*/
    console.log(mostRecentPocketPost)
    const finalePosts = posts.filter(x=> x.id !== mostRecentPost.map(x => x.id)[0])

    if(loading){ 
        return(
        <div>
            <h1>Loading............</h1>
        </div>);
    }
    if(!loading && isError){
        return (
            <div>
                <h1>Sorry an error occured</h1>
            </div>
        );
    }
    return(
        <section className="section bg-secondary">
            <Container>
              <Row className="row-grid align-items-center">
                <Col md="6">
                  <Card className="bg-default shadow border-0">
                    <CardImg
                      alt="..."
                      //src={require("assets/img/theme/img-1-1200x1000.jpg")} 
                      src ={mostRecentPost.map(x => x.photoUrl)[0]} 
                      top
                    />
                    <blockquote className="card-blockquote">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="svg-bg"
                        preserveAspectRatio="none"
                        viewBox="0 0 583 95"
                      >
                        <polygon
                          className="fill-default"
                          points="0,52 583,95 0,95"
                        />
                        <polygon
                          className="fill-default"
                          opacity=".2"
                          points="0,42 583,95 683,0 0,95"
                        />
                      </svg>
                      <h4 className="display-3 font-weight-bold text-white">
                        <a className ="font-weight-bold text-white" href={mostRecentPost.map(x => x.orginalUrl)[0]}  target="blank">{mostRecentPost.map(x => x.title)[0]}</a>
          
                      </h4>
                      <p className="text-white text-muted">{mostRecentPost.map(x => moment(x.timeUpdated).format("MMMM DD, YYYY"))[0]} | By {mostRecentPost.map(x => x.author)[0]} </p>
                      <br />
                      <p className="lead text-italic text-white">
                        {mostRecentPost.map(x => x.content)[0]}
                      </p>
                      <a
                          className="font-weight-bold text-success mt-5"
                          href={mostRecentPost.map(x => x.orginalUrl)[0]}
                          target="blank"
                          //onClick={e => e.preventDefault()}
                        >
                        Read More
                        </a>
                        <br />
                        <br />
                        <br />
                        <br />
                    </blockquote>
                  </Card>
                </Col>
                <Col md="6">
                  <div className="pl-md-5">
                    <div className="icon icon-lg icon-shape icon-shape-warning shadow rounded-circle mb-5">
                      <i className="ni ni-settings" />
                    </div>
                    <h3>LATEST NEWS</h3>
                    {finalePosts.map(x=>
                        <div>
                            <h5 className="">
                            <a className ="font-weight-bold text-dark" 
                                href={x.orginalUrl}
                                target="blank">{x.title}</a>
                            </h5>
                            <p className="">{moment(x.timeUpdated).format("MMMM DD, YYYY")} | By {x.author} </p>
                            <p>{truncate(x.content, 150)}</p>
                            
                            <a
                            className="font-weight-bold text-success mt-5"
                            href={x.orginalUrl}
                            target="blank"
                            //onClick={e => e.preventDefault()}
                            >
                            Read More
                            </a>
                            <hr/>
                        </div>
                     )}
                  </div>
                </Col>
              </Row>
            </Container>
          </section>
        
    )
}
export default BlogSummary;