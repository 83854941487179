import { Axios } from "../config";
import requestToken from "./../pocket/auth/req_token"

export const getPosts = async () => {
    try {
        const res = await Axios.get('posts');
        const newRes = res.data.list.sort((a, b) => { 
            let firstDate = new Date( a.date ) 
            let secondDate = new Date( b.date )
            return  firstDate - secondDate
        });
        return {data:newRes.reverse(), error:false} 
    }catch(error){
        return {data:[], error: true}
    }
}

export const newPost = async (data) => {
    try {
        const res = await Axios.post('posts', data);
        console.log(res.data)
        return {res:res.data, error:false}
        
    }catch(error){
        return {data:[], error: true}
        
    }
}

export const getMostRecentPost = async (numberOfPost) => {
    try {
        const res = await Axios.get('posts');
        const newRes = res.data.list.sort((a, b) => {
            let firstDate = new Date( a.date ) 
            let secondDate = new Date( b.date )
            return  firstDate - secondDate
        });
        const mostRecentPost = newRes.slice(-numberOfPost)
        //const mostRecentPost = res.data.sortBy(function(o){ return new Date( o.date ) });//res.data.filter(d=>d.date=== '2022-11-01')
        //console.log();
        return {data:mostRecentPost, error:false}
    }catch(error){
        return {data:[], error: true} 
    }
}

export const getPocketAuth = async () => {
        try{
            const resAuth = await Axios.get('auth/pocket')
            return resAuth
            //console.log(resAuth.data.conf)
            //window.open(`https://getpocket.com/auth/authorize?request_token=${resAuth.data.conf.request_token}&redirect_uri=${resAuth.data.conf.redirect_uri}`, '_blank');
            
            /*const authorisePocketTime = setTimeout(async ()  => {
                const resSec = await Axios.get('/pocket/retrieve')
                console.log('waited 5 sec')
                console.log(resSec)
            }, 30000);*/
            
            
            
        }catch(err){
            //window.open('https://getpocket.com/auth/authorize?request_token=4eeaa9f3-e078-d634-d845-ca11cc&redirect_uri=https://patbpm-fluffy-telegram-77697wjxv9vfxr5v-5000.preview.app.github.dev/api/auth/pocket/callback', '_blank');
        }
    

}
export const getPocketAccessCode = async () => {
    try {
        //const res = await Axios.get('/pocket/retrieve')
        const res = await Axios.get('/auth/pocket/callback')
        return res
    }catch(error){
        return {data:[], error: true}
    }

}

export const getMostRecentPocketPost = async () => {
    try {
        const res = await Axios.get('/pocket/retrieve')
        return {data:res, error:false}
    }catch(error){
        return {data:[], error: true}
    }
}

export const archivedRecentPocketPost = async () => {
    try {
        const res = await Axios.get('/pocket/archived')
        console.log(res)
        return {data:res, error:false}
    }catch(error){
        return {data:[], error: true}
    }
}
