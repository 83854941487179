/*!

=========================================================
* Argon Design System React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-design-system-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-design-system-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, {useEffect, useState, useRef} from "react";
// nodejs library that concatenates classes
import classnames from "classnames";
import { useForm, Controller } from "react-hook-form";
import emailjs from "emailjs-com";


// reactstrap components
import {
  Badge,
  Button,
  Card,
  CardBody,
  CardImg,
  FormGroup,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col
} from "reactstrap";




function NewsLetterForm (){
    const [nameFocused, setNameFocused]= useState(false);
    const { register, handleSubmit, reset, control, formState: { errors, isSubmitSuccessful } } = useForm();
    const form = useRef();

    const sendEmail = (e) => {
      emailjs.sendForm(process.env.REACT_APP_SERVICE_ID,
        process.env.REACT_APP_TEMPLATE_ID,
        form.current,
        process.env.REACT_APP_PUBLIC_KEY
        
        /*'service_j02l3br', 'template_68bd4k4', form.current, '8_3TIl8YSOsv7-bAl'*/)
        .then((result) => {
            console.log(result.text);
        }, (error) => {
            console.log(error.text);
        });
      reset();
    };
    return(
        <section className="section section-lg pt-lg-0 section-contact-us">
            <Container>
              <Row className="justify-content-center mt--300">
                <Col lg="8">
                  <Card className="bg-gradient-secondary shadow">
                    <CardBody className="p-lg-5">
                      <h4 className="mb-1">Find Out More and Sign Up For Our Newsletter!</h4>
                      <p className="mt-0">
                        Please provide your details below to be kept up to date with new features and developments.
                      </p>
                      <form  ref={form} onSubmit={handleSubmit(sendEmail)}>
                        <FormGroup
                          className={classnames("mt-5", {
                            focused: nameFocused
                          })}
                        >
                            <InputGroup className="input-group-alternative">
                              <InputGroupAddon addonType="prepend">
                                <InputGroupText>
                                  <i className="ni ni-user-run" />
                                </InputGroupText>
                              </InputGroupAddon>
                              <input
                                className="form-control"
                                placeholder="Your name"
                                type="text"
                                onFocus={e => setNameFocused(true)}
                                onBlur={e => setNameFocused(false)}
                                {...register("name", { required: true})}
                              />
                            </InputGroup>
                          </FormGroup>
                          <FormGroup
                            className={classnames({
                              //focused: this.state.emailFocused
                            })}
                          >
                            <InputGroup className="input-group-alternative">
                              <InputGroupAddon addonType="prepend">
                                <InputGroupText>
                                  <i className="ni ni-email-83" />
                                </InputGroupText>
                              </InputGroupAddon>
                              <input
                                className="form-control"
                                placeholder="Email address"
                                type="email"
                                onFocus={e => setNameFocused(true)}
                                onBlur={e => setNameFocused(false)}
                                {...register("email", { required: true})}
                              />
                            </InputGroup>
                          </FormGroup>
                          <FormGroup className="mb-4">
                            <textarea
                              
                              className="form-control"
                              cols="80"
                              name="name"
                              placeholder="Type a message..."
                              rows="4"
                              type="textarea"
                              {...register("comment")}
                            />
                          </FormGroup>

                      
                      
                      <div>
                        <Button
                          block
                          className="btn-round"
                          color="default"
                          size="lg"
                          type="submit"
                        >
                          Send Message
                        </Button>
                      </div>
                      </form>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Container>
          </section>
    )
}
export default NewsLetterForm;
