import React, {useEffect, useState} from "react";
import { getPosts, getMostRecentPost, getMostRecentPocketPost } from "./../../services/api/index";
import moment from "moment";
import { truncate } from "services/config";
import DemoNavbar from "components/Navbars/DemoNavbar";
import SimpleFooter from "components/Footers/SimpleFooter";
import PaginationSection from "components/Pagination"; 
import NewsSettings from "components/NewsSettings";
import { useLocation } from "react-router-dom";
// reactstrap components
import {
    Badge,
    Button,
    Card,
    CardBody,
    CardImg,
    Container,
    Row,
    Col,
    UncontrolledCarousel ,
    FormGroup,
    Input,
    InputGroupAddon,
    InputGroupText,
    InputGroup,
  } from "reactstrap";

function News (){
    const [posts, setPosts]= useState([]);
    const [isError, setIsError] = useState(false);
    const [loading, setLoading] = useState(true);
    const [isAdmin, setIsAdmin] = useState(false);

    const location = useLocation();
    console.log(location);

    useEffect(()=> {
        const fetchData = async() => {
            const res = await getPosts();
            setIsError(res.error);
            setPosts(res.data);
            setLoading(false)
        };
        fetchData();
        return () => {};
    }, []);

    const finalePosts = posts

    if(loading){ 
        return(
        <div>
            <h1>Loading............</h1>
        </div>);
    }
    if(!loading && isError){
        return (
            <div>
                <h1>Sorry an error occured</h1>
            </div>
        );
    }
    return(
        <>
        <DemoNavbar />
        <section className="section bg-primary">
            <Container>
              <br/>
              <br/>
              <br/>
             <div>
              <h3 className="font-weight-bold text-dark text-center">CURRENT FOOD INDUSTRY NEWS</h3>
              {isAdmin?<NewsSettings />:""}
             </div> 
             
             <br/>
             <br/>
            {finalePosts.map(x=>
              <Row className="row-grid align-items-center">
                <Col md="4">
                  <a href={x.orginalUrl}
                                target="blank">
                  <div className="px-4"> 
                      <img
                        alt="..."
                        className="rounded img-center img-fluid shadow shadow-lg--hover"
                        src={x.photoUrl}
                        style={{ width: "200px" }}
                      />
                    </div>
                  </a>
                </Col>
                <Col md="8">
                  <div className="pl-md-5">
                        <div>
                            <h5 className="">
                            <a className ="font-weight-bold text-dark" 
                                href={x.orginalUrl}
                                target="blank">{x.title}</a>
                            </h5>
                            <p className="text-white">{moment(x.timeUpdated).format("MMMM DD, YYYY")} | By {x.author} </p>
                            <p className="text-white">{x.content}</p>
                            
                            <a
                            className="font-weight-bold text-success mt-5"
                            href={x.orginalUrl}
                            target="blank"
                            //onClick={e => e.preventDefault()}
                            >
                            Read More
                            </a>
                            <hr/>
                        </div>
                     
                  </div>
                </Col>
                  
              </Row>
              )}
              <br />
              
             {/*<PaginationSection />*/}
            </Container>
            <Button 
                type="button"  
                className="icon icon-sm icon-shape icon-shape-primary rounded mb-5" 
                block
                onClick={() => setIsAdmin(true)}
                >
                      <i className="ni ni-fat-add" />
            </Button>
          </section>
          <SimpleFooter />
          </>
    )
}
export default News;