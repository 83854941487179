import React, {useEffect, useState} from "react";
//https://demos.creative-tim.com/vue-argon-design-system/documentation/foundation/icons.html
//import NewsForm from "views/examples/NewsForm";
import { useForm, Controller } from "react-hook-form";
import { useLocation } from "react-router-dom";
import classnames from "classnames";
import ReactDatetime from "react-datetime";
import {newPost, getMostRecentPocketPost, archivedRecentPocketPost } from "./../services/api/index";
// reactstrap components
import {
    Badge,
    Button,
    Modal,
    Card,
    CardBody,
    CardImg,
    Container,
    Row,
    Col,
    UncontrolledCarousel ,
    FormGroup,
    Input,
    InputGroupAddon,
    InputGroupText,
    InputGroup,
    UncontrolledAlert,
  } from "reactstrap";

function NewsSettings (){
    const [modal, setModal]= useState(false);
    const [loadModal, setLoadModal]= useState(false);
    const [loading, setLoading]= useState(false);
    const [visible, setVisible]= useState(false);
    const [alert, setAlert]= useState('failed');
    const [nameFocused, setNameFocused]= useState(false);
    const [post, setPost]= useState([]);

    const toggle = () => setModal(!modal);
    const toggleLoad = () => setLoadModal(!loadModal);
    const onDismiss = () => setVisible(false);

    const location = useLocation();
    console.log(location);

    const { register, handleSubmit, reset, control, formState: { errors, isSubmitSuccessful } } = useForm();
    
    const alerting = () => {
        return (<>
        {alert === 'Succeeded'?
            <UncontrolledAlert color="success" fade={true} isOpen={visible} toggle={onDismiss}>
                <span className="alert-inner--icon">
                    <i className="ni ni-like-2" />
                </span>
                <span className="alert-inner--text ml-1">
                    <strong>Success!</strong> New post have been added!
                </span>
            </UncontrolledAlert>
            :
            <UncontrolledAlert color="danger" fade={false} isOpen={visible} toggle={onDismiss}>
                <span className="alert-inner--icon">
                    <i className="ni ni-support-16" />
                </span>
                <span className="alert-inner--text ml-1">
                    <strong>Error!</strong> Failed to add and post!
                </span>
            </UncontrolledAlert>}
            
        </>)
    }

    useEffect(() => {
        if(alert==='Succeeded' && isSubmitSuccessful){
            reset({
                title: "",
                photoUrl: "",
                content:"",
                orginalUrl:"",
                timeAdded:"",
                author:"",
            })
        }
        
      }, [alert, isSubmitSuccessful ])

    
    const onSubmit = (data) => {
       
        console.log(data)
        const fetchData = async() => {
            const postInfo = await newPost(data);
            console.log(postInfo.res)
            setVisible(true);
            postInfo.res.success?setAlert("Succeeded"):setAlert("failed")
           //setPost(res.data);
            //setLoading(false)
        };
        fetchData();
        return () => {};
    
    
    };

    const retreivepocket = ()=> {
        setLoadModal(true)
        const fetchData = async() => {
            const res = await getMostRecentPocketPost();
            console.log(res)
            window.open(res.data.data.authUrl,  '_blank') 
            //window.location.href = `${location.pathname}` 
  
            
        };
        fetchData(); 
        return () => {};
    }

    const archivepocket = ()=> {
        const fetchData = async() => {
            const res = await archivedRecentPocketPost(); 
            console.log(res) 
            
            
        };
        fetchData(); 
        return () => {};
    }

    const handleCloseModal = () =>{
        setLoadModal(false)
        window.location.href = `${location.pathname}`
    }

    return(
    <>
        
        <div>
            
            <Button 
                type="button"  
                className="icon icon-lg icon-shape icon-shape-primary shadow rounded mb-5" 
                block
                onClick={() => setModal(true)}
                >
                      <i className="ni ni-fat-add" />
            </Button>
            <Button className="icon icon-lg icon-shape icon-shape-warning shadow rounded mb-5"
            onClick={() => retreivepocket()}>
                      <i className="ni ni-cloud-upload-96" />

            </Button>

            <Modal
                className="modal-dialog-centered"
                isOpen={modal}
                toggle={toggle}
                size="lg"
                >
                <div className="modal-header text-center">
                    
                    <h4 className="modal-title font-weight-bold " id="modal-title-default">
                    ADD NEW NEWS
                    </h4>
                    <button
                    aria-label="Close"
                    className="close"
                    data-dismiss="modal"
                    type="button"
                    onClick={() => setModal(false)}
                    >
                    <span aria-hidden={true}>×</span>
                    </button>
                </div>
                <form onSubmit={handleSubmit(onSubmit)}>
                    {alerting()}
                    <div className="modal-body">
                    
                        <FormGroup
                            className={classnames("mt-5", {
                            focused: nameFocused
                            })}
                        >
                            <InputGroup className="input-group-alternative">
                            <InputGroupAddon addonType="prepend">
                                <InputGroupText>
                                <i className="ni ni-ui-04" />
                                </InputGroupText>
                            </InputGroupAddon>
                            <input
                                className="form-control"
                                placeholder="Title"
                                type="text"
                                onFocus={e => setNameFocused(true)}
                                onBlur={e => setNameFocused(false)}
                                name = "title"
                                {...register("title", {required:true})}
                            />
                            </InputGroup>
                            {errors.title && <small className="text-muted  text-danger">This field is required</small>}
                        </FormGroup>
                        <FormGroup
                            className={classnames({
                            focused: nameFocused
                            })}
                        >
                            <InputGroup className="input-group-alternative">
                            <InputGroupAddon addonType="prepend">
                                <InputGroupText>
                                <i className="ni ni-world-2" />
                                </InputGroupText>
                            </InputGroupAddon>
                            <input
                                placeholder="Photo Url"
                                className="form-control"
                                type="text"
                                defaultValue={'https://www.food-safety.com/ext/resources/eDigest/2023/robot-picking-tomato.jpg?1683640577'}
                                onFocus={e => setNameFocused(true)}
                                onBlur={e => setNameFocused(false)}
                                {...register("photoUrl")}
                            />
                            </InputGroup>
                        </FormGroup>
                        <FormGroup className="mb-4">
                            <textarea
                            className="form-control"
                            cols="80"
                            name="name"
                            placeholder="Add the news content extract..."
                            rows="4"
                            type="textarea"
                            {...register("content", { required: true})}
                            />
                            {errors.content && <small className="text-muted  text-danger">This field is required</small>}
                        </FormGroup>
                        <FormGroup
                            className={classnames({
                            focused: nameFocused
                            })}
                        >
                            <InputGroup className="input-group-alternative">
                            <InputGroupAddon addonType="prepend">
                                <InputGroupText>
                                <i className="ni ni-world-2" />
                                </InputGroupText>
                            </InputGroupAddon>
                            <input
                                placeholder="Original Url"
                                className="form-control"
                                type="text"
                                onFocus={e => setNameFocused(true)}
                                onBlur={e => setNameFocused(false)}
                                {...register("orginalUrl", { required: true })}
                            />
                            </InputGroup>
                            {errors.originalUrl && <small className="text-muted  text-danger">This field is required</small>}
                        </FormGroup>
                        <FormGroup>
                            <InputGroup>
                                <InputGroupAddon addonType="prepend">
                                    <InputGroupText>
                                        <i className="ni ni-calendar-grid-58" />
                                    </InputGroupText>
                                </InputGroupAddon>
                                <Controller
                                    name="timeAdded"
                                    control={control}
                                    required
                                    render={({ field }) => (
                                        <ReactDatetime
                                            {...field}
                                            inputProps={{
                                                placeholder: "When Added....",
                                            }}
                                            viewMode="time"
                                        />
                                    )}
                                />
                            </InputGroup>
                        </FormGroup>
                        <FormGroup
                            className={classnames({
                            focused: nameFocused
                            })}
                        >
                            <InputGroup className="input-group-alternative">
                            <InputGroupAddon addonType="prepend">
                                <InputGroupText>
                                <i className="ni ni-single-02" />
                                </InputGroupText>
                            </InputGroupAddon>
                            <input
                                placeholder="Author"
                                className="form-control"
                                type="text"
                                onFocus={e => setNameFocused(true)}
                                onBlur={e => setNameFocused(false)}
                                {...register("author")}
                            />
                            </InputGroup>
                        </FormGroup>

                    
                                        
                        
                    </div>
                    <div className="modal-footer">
                        <Button color="primary" type="submit">
                        Save changes
                        </Button>
                        <Button
                        className="ml-auto"
                        color="link"
                        data-dismiss="modal"
                        type="button"
                        onClick={() => setModal(false)}
                        >
                        Close
                        </Button>
                    </div>
                </form>
            </Modal>

            <Modal
              className="modal-dialog-centered modal-success"
              contentClassName="bg-gradient-success"
              isOpen={loadModal}
              toggle={toggleLoad}
                >
                <div className="modal-header">
                    <h6 className="modal-title " id="modal-title-notification">
                    CONGRATUTALTION - ARTICLE UPLAODED
                    </h6>
                    <button
                    aria-label="Close"
                    className="close"
                    data-dismiss="modal"
                    type="button"
                    onClick={() => handleCloseModal()}
                    >
                    <span aria-hidden={true}>×</span>
                    </button>
                </div>
                <div className="modal-body">
                    <div className="py-3 text-center">
                    <i className="ni ni-bell-55 ni-3x" />
                    <h4 className="heading mt-4">Awesome, Your Articles are uploaded!</h4>
                    </div>
                </div>
                <div className="modal-footer">
                    <Button className="btn-white" color="default" type="button" 
                    onClick={() => handleCloseModal()}>
                      <i className="ni ni-archive-2 text-danger icon-shape icon-shape-primary " />    
                       OK, COOL
                    </Button>
                    <Button
                    className="text-white ml-auto"
                    color="link"
                    data-dismiss="modal"
                    type="button"
                    onClick={() => handleCloseModal()}
                    >
                    Close
                    </Button>
                </div>
            </Modal>
           
            
        </div>
    
    </>
        
    )
}
export default NewsSettings;